<script lang="ts" setup>
const document = ref();
usePageNavigation(document, "document");
</script>

<template>
  <ScrollWrapper>
    <Navigation />
    <Activity
      :class="
        cn(
          'relative z-50 min-w-60 w-[calc(100vw-240px)] sm:w-100',
          'h-full shrink-0 border-r border-strong flex flex-col relative bg-color-1',
          'max-xl:snap-start'
        )
      "
    />
    <NuxtPage />
  </ScrollWrapper>
</template>
